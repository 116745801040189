import * as React from 'react';
import { TerminalCommunicationService as tcs, endOfProcess, model } from '@fresche/terminal-lib';
import AppHeader from '../app-header/AppHeader';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Subscription } from 'rxjs';
import './Menu.css';

export interface MenuState {
  menu: any;
  menuError: boolean;
  activeIndex: any;
  isMounted: boolean;
}

export default class Menu extends React.Component<any, MenuState> {
  protected terminal = new tcs();
  public eofSub: Subscription;
  public formData: any;
  private basedir: string;
  private modelSub: Subscription; // Mod-e-l

  constructor(props: any) {
    super(props);
    this.state = { menu: undefined, menuError: false, activeIndex: undefined, isMounted: false };
    this.basedir = window.config && window.config['app'] ? window.config['app']['basedir'] : '/';
    this.eofSub = endOfProcess.subscribe((eof: any): void => {
      if (eof !== null) {
        this.terminal.gotoLocation(this.basedir + 'masterMenu');
        endOfProcess.next(null);
      }
    });

    this.modelSub = model.subscribe((m: any): any => {
      if (m) {
        if (m.screenId && m.screenId === '_screenConfirm') {
        } else {
          if (m.data) {
            this.formData = m.data;
          }
        }
      }
    });
  }

  /**
 * Stops the loaders.
 */
  hideLoader(): void {
    const loaderElement = document.getElementById('loaderModal');
    if (loaderElement) {
      loaderElement.classList.remove('show');
    }
  }

  componentDidMount(): void {
    this.loadMenu();
    this.hideLoader();
  }

  componentWillUnmount(): void {
    this.setState({ isMounted: false });
    if (this.eofSub) {
      this.eofSub.unsubscribe();
    }
    if (this.modelSub) {
      this.modelSub.unsubscribe();
    }
  }

  loadMenu(): void {
    const menuFile = (process.env.NODE_ENV === 'development' ? process.env.PUBLIC_URL + './menu.json' : './menu.json');

    this.setState({ isMounted: true }, (): any => {
      fetch(menuFile)
        .then((r: any): any => {
          return r.json();
        })
        .then((result: any): void => {
          if (this.state.isMounted) {
            this.setState({ menu: result });
          }
        }).catch((_error: any): void => {
          if (this.state.isMounted) {
            this.setState({ menuError: true });
          }
        });
    });
  }

  handleMenuClick(screen: string): void {
    this.formData['fields']['optionSelected'] = screen;
    this.terminal.reply();
  }

  render(): React.ReactElement {
    return (
      <React.Fragment >
        <div style={{ padding: '130px 2.5% 14px' }}>
          <AppHeader
            screenDef={null}
            formData={null}
          />
          <div className="container-fluid app-title-header">
            <h1>Main Menu - <span>DEFAULT</span></h1>
          </div>
          {this.state.isMounted && (
            <div className="container-fluid" style={{ width: '60%' }}>
              {this.state.menu !== undefined && (
                <Accordion
                  activeIndex={this.state.activeIndex}
                  onTabChange={(e: any): void => this.setState({ activeIndex: e.index })}
                >
                  {this.state.menu.sections.map((section: any, index: any): any => {
                    return (<AccordionTab key={'tab' + index} header={section.section}>
                      <ul>
                        {section.programs.map((program: any, i: any): any => {
                          return (
                            <li key={'li' + i}>
                              <Button
                                label={program.label}
                                onClick={(e: any): void => {
                                  e.preventDefault();
                                  this.handleMenuClick(program.name);
                                }}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </AccordionTab>);
                  })}
                </Accordion>
              )}
              <Button
                label="EXIT TO TOP MENU"
                onClick={(e: any): void => {
                  e.preventDefault();
                  this.terminal.gotoLocation('/');
                }}
              />
              {this.state.menuError &&
                <div className="menu-error">
                  <p><strong>Error with the menu.json file.</strong>
                                    Make sure that the file is located in the assets folder and that it is valid.
                            </p>
                </div>
              }
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
