import * as React from 'react';
import ReactDOM from 'react-dom';
import './AppFooter.css';
import Logout from '../logout/Logout';
import 'primeflex/primeflex.css';
import { Footer } from "@better-ui/react/";
import { Button } from "@better-ui/react";
import "@better-ui/styles/optum/optum.css";


declare global {
  interface Window { runConfig: any; }
}

export interface AppFooterState {
  currentDateTime: String;
}

export interface AppFooterProps {
  screenDef: any;
}

declare global {
  interface Window { runConfig: any; }
}
/**
*
*/
export interface AppFooterProps {
  userName: any;
}
class AppFooter extends React.Component<AppFooterProps, AppFooterState> {
  private logoSrc = './images/Optum-Rx-wht-RGB.svg';
  private intervalID: NodeJS.Timeout

  constructor(props: AppFooterProps) {
    super(props);
    this.state = { currentDateTime: Date().toLocaleString() };
  }

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      currentDateTime: new Date().toLocaleString('en-US', { hour12: false })
    });
  }

  openHelpText() {
    const helpPage = "/help/index.htm#t=Pharmacy_Network/Pharmacy_Network%2F" + this.props.screenDef.programName + ".htm";
    window.open(
      helpPage,
      'Help'
    );
    return;
  }

  render(): React.ReactElement {
    const manageNavigationMessage = function (e: MessageEvent) {
      if(e.data.message === "getProgramName") {
        window.parent.postMessage(
        {
          message: "sendProgramName",
          programName: this.props.screenDef.programName,
          application: "Pharmacy_Network/Pharmacy_Network"
        }, '*');
      }
    }
    window.addEventListener("message", manageNavigationMessage.bind(this));

    const env = process.env.REACT_APP_API_ENV;
    let displayUser;
    let logoutDisplay;
    const version=window['version'];
    const doLogout = () => {
      //sessionStorage.clear();
      window.parent.postMessage("logout", '*');
      return ReactDOM.render(
        <React.StrictMode>
          <Logout />
        </React.StrictMode>,
        document.getElementById('root')
      );
    }
    if (this.props.userName) {
      // split username to get first name only
      const fullName = this.props.userName.split(" ");
      const firstName = fullName[0];
      displayUser = <button> Hello {firstName} </button>;
      logoutDisplay = <span className="logout"><span className="section-divider-right"></span><button onClick={doLogout} >Sign Out</button></span>;
    }
    else {
      displayUser = <button>Sign In</button>;
    }

    const DefaultLogo =() =>{
      //apply environment button dynamically based on the environment

      if (env == "local") {

        return <div className="bui-button footer-btn-local" ><span  className="footerBtnWrapper"><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></span></div>
      }
      if (window.location.href.includes("dev")) { 

        return <div className="bui-button footer-btn-dev" ><span  className="footerBtnWrapper"><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></span></div>
      }
      if (window.location.href.includes("qa") ) { 

        return <div className="bui-button footer-btn-qa" ><span  className="footerBtnWrapper"><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></span></div>
      }
      if (window.location.href.includes("stg") ) { 

        return <div className="bui-button footer-btn-stg" ><span  className="footerBtnWrapper"><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></span></div>
      }
      else  { 

        return <div className="bui-button footer-btn-prod" ><span  className="footerBtnWrapper"><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></span></div>
    }
    
  }

    return (
      <Footer className="footerContainer" /*logoContent={<DefaultLogo theme={"bui-uhc-theme"}/>}*/ /*linkConfig={{links: [{href:'#', label: <DefaultLogo theme={"bui-uhc-theme"} />, className: "linkStyle"}]}}*/>
      
       <DefaultLogo  ></DefaultLogo>
      </Footer>
    );
  }
}

export default AppFooter;
