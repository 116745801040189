import * as React from 'react';

export default class StateList {
    getStatusList() {
        const options = [
            {
                value: "A",
                label: "Active"
            },
            {
                value: "I",
                label: "Inactive"
            },
            {
                value: "",
                label: "All"
            }
        ];

        return options;
        
    }
}