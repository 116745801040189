import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { RouteComponentProps } from "react-router";
import OptumTerminalCommunicationService from "../../util/OptumTerminalCommunicationService";


class StartProgram extends React.Component<RouteComponentProps, {}> {
    terminal: OptumTerminalCommunicationService;

    constructor(props: RouteComponentProps) {
        super(props);
        console.log("props::" + props);
        this.terminal = new OptumTerminalCommunicationService();
        const programPath = this.props.location.pathname;
        console.log("programPath :: " + programPath);
        const programParams = programPath.substr(programPath.indexOf('/program/')+9).split("/");
        console.log("programParams :: " + programParams);
        const programName = programParams.shift();
        console.log("programName :: " + programName);
        this.terminal.startSpecificProcess(
            programName,
            programParams);
    }

    render(): React.ReactElement {
        return <div id="loaderModal" className="modal">
            <ProgressSpinner className="loader" />
        </div>
    }
}

export default StartProgram;

