import * as React from 'react';
import { FGrid, FTextInput, FSelectInput } from '@fresche/ui-lib-react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { model, TerminalCommunicationService } from '@fresche/terminal-lib';

// Note: These paths may need updated, depending on where this file lives
import BaseView from '../../base/view/base-view';
import CustomBase from './CustomBase';
import OptumGrid from "../ui/OptumGrid";
import StatusList from './StatusList';

// Implement our custom Component, extending the BaseView component
export default class JavaToPresto extends BaseView {
    public prescriberData: any;
    public stateList: any;
    public selectedActionField = 'selected';
    public statusList: any;
    private readonly HELP_PAGE_WINDOW_SETTINGS = 'toolbar=no, scrollbars=yes, menubar=no, status=no, directories=no, width=600, height=400';
    public state: any;
    public formData;

      // A constructor is required so we can call the super.initialize() method
    constructor(props: any, screenName: string, isDialog: boolean, isMultiPage?: boolean) {
        super(props, screenName, isDialog, isMultiPage);
        super.initialize();
        this.state = {
            items: [],
            dialogVisible: false
        }
        this.statusList = new StatusList();
    }

    replyToBackend(newFormData: any): void {
        // ... terminal lib need a refresh of its model
        model.next({
            data: newFormData
        });
        //this.terminal.reply();
        console.log(this.formData)
    }

    showLoader(): void {
        const loaderElement = document.getElementById('loaderModal');
        if (loaderElement) {
            loaderElement.classList.add('show');
        }
    }

    // Once the component is mounted, do an AJAX request to GitHub, which
    // we'll add to our Modal item list.
    componentDidMount(): void {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    hideDialog() {
        this.setState({ dialogVisible: false })
    }

    openHelpText() {
        const helpPage = "/help/index.htm#t=Prescriber_Search%2FPresc_Snapshot_-_DEA_Details.htm";
          window.open(
            helpPage,
            'Help',
            this.HELP_PAGE_WINDOW_SETTINGS
          );
          return;
      }
    // Java sends a program name that is determined based on the Java InputParameterClass     
    // Assumption is that the first 9 position of the input parameter class name is set as the program name   
    // In many cases the 9th position contains a "I" and this works fine.
    // But there are some cases where the 9th position is a different character. If this is the case we remove it 
    // and use the first 8 characters as a program name
    // This only strips the last character if it is alpha and not a "I"
    formatProgramNameForRxClaimWeb(programName) {
        const lastCharacter = programName[programName.length - 1];
        // If last character is alpha but not a "I", trim it
        if (lastCharacter !== 'I' && lastCharacter.match(/[a-z]/i)) {
        return programName.substring(0, programName.length - 1);
        }
        return programName;
    }
    // renderContent is the function in BaseView that renders the core page content, overriding
    // this method allows us to keep the header for the page from BaseView, while only customizing
    // the page specific output
    renderContent(): React.ReactElement {
        const programName=this.formData['gdo']['fields']['rcd.programName'];
        const programNameFormated=this.formatProgramNameForRxClaimWeb(this.formData['gdo']['fields']['rcd.programName']);
        const prestoParams=this.formData['gdo']['fields']['rcd.params'];

        // post message to send program name and params to unifiedUI
        window.parent.postMessage(
            {
              message: "navigate-to-presto",
              programCode: programName,
              parameters : prestoParams
            }, '*')

        // We have to wrap the custom output in a CustomBase component, otherwise we lose access to
        // data in some components (FGrid), as well as key handlers (F3, Enter, etc).
        return (
            <CustomBase type="view" screenDef={this.screenDef} formData={this.formData} isActiveScreen={!this.displayDialog}>
                <br></br>
                <br></br>
                <h1>{this.screenDef.title}</h1>
                <br></br>
                <p>This screen {programNameFormated} is available in RxClaim Web and has opened up in a separate tab.</p>

            </CustomBase>
        );
    
    }

}