type programInformation = {
    programName: string,
    inputParameterClass?: string,
    inputParameterNames?: string[]
}

type programInformations = {
    [name: string]: programInformation
}

const programs: programInformations = {

    RCPHN002: {
        programName: 'com.optum.file.phnpharmacynetwork.program.rcphn002dispnetwork.IRcphn002DispNetworkService'
    },
    RCSNH002 : {
        programName: 'com.optum.file.snhsupernetworkheader.program.rcsnh002dispnetwork.IRcsnh002DispNetworkService'
    }
};

export function getProgramParameters(programName: string): programInformation | undefined {
    return programs[programName];
}


export function getProgramUrl(programName: string, parameters?: string[]): string | undefined {
    // get program informations
    let url = undefined;
    // return program url
        const encodedParameters: string[] = parameters && parameters.length > 0 ? parameters.map(elt=> encodeURIComponent(elt as string)) : [];
        const urlParameters = encodedParameters.length > 0 ? "/" + encodedParameters.join("/") : "";
        url = `/program/${programName}${urlParameters}`
    
    console.log("Navigating to " + url);
    return url;
}

