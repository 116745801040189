import * as React from 'react';
import { TerminalCommunicationService } from '@fresche/terminal-lib';
import AppHeader from '../app-header/AppHeader';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import './OnError.css';

declare global {
  interface Window { config: any; }
}

export default class OnError extends React.Component<any, any> {
  private terminal: TerminalCommunicationService;

  handleHomeClick(): void {
    this.terminal = new TerminalCommunicationService();
    this.terminal.init({}, {}, window.config);
    this.terminal.gotoLocation('/');
  }

  render(): React.ReactElement {
    const header = <img alt="Card" src='./images/500error.png' />;
    const footer = (<span>
      <Button
        label="Home"
        icon="pi pi-home"
        onClick={(e: any): void => {
          e.preventDefault();
          this.handleHomeClick();
        }}
      />
    </span>);
    return (
      <React.Fragment>
        <AppHeader
          screenDef={null}
          formData={null}
        />
        <Card footer={footer} header={header} className="card ui-card-shadow">
          An error occured somewhere in the backend
        </Card>
      </React.Fragment>
    );
  }
}
