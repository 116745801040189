const fetchOriginal = fetch;

/**
 * To override fetch with authentication, use with
 *      window.fetch = generateFetchWithAuth(accessToken);
 *
 * @param token the use token
 */
const generateFetchWithAuth = (token: string, idToken: string,auth_type: string) => {
    return (input: RequestInfo, init?: RequestInit): Promise<Response> => {
        const params = init ? init : {} as RequestInit;
        const requestHeaders: HeadersInit = new Headers(params.headers);
        params.headers = requestHeaders;
        requestHeaders.set('Authorization', 'Bearer '+token);
        requestHeaders.set('id_token', idToken);
        requestHeaders.set('Auth-Type', auth_type);
        return fetchOriginal(input, params);
    }
}

const generateFetchWithSession = (session_id: string, auth_type: string) => {
    return (input: RequestInfo, init?: RequestInit): Promise<Response> => {
        const params = init ? init : {} as RequestInit;
        const requestHeaders: HeadersInit = new Headers(params.headers);
        params.headers = requestHeaders;
        requestHeaders.set('Session-ID', session_id);
        requestHeaders.set('Auth-Type', auth_type);
        return fetchOriginal(input, params);
    }
}
const generateFetchWithTenantId = (tenant_id: string) => {
    return (input: RequestInfo, init?: RequestInit): Promise<Response> => {
        const params = init ? init : {} as RequestInit;
        const requestHeaders: HeadersInit = new Headers(params.headers);
        params.headers = requestHeaders;
        requestHeaders.set('tenant-id', tenant_id);
        return fetchOriginal(input, params);
    }
}

export {generateFetchWithAuth,generateFetchWithSession,generateFetchWithTenantId};